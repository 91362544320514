import { API_URL } from '../config';
import { checkToken, ReturnType } from './checkToken';
import axios, { AxiosRequestConfig,  CancelTokenSource} from 'axios';

export async function callApi(payload: {
  method: 'get' | 'post' | 'put' | 'delete' | 'patch';
  path: string;
  data?: Object | null;
  refreshToken?: string;
  isFormData?: boolean;
  isBlobResponseType?: boolean;
  anotherDomain?: boolean;
  cancelSource?: CancelTokenSource;
}) {
  const { method, path, data, refreshToken, isFormData, anotherDomain, cancelSource } = payload;

  const mustCheckToken = path !== '/login' && !refreshToken;
  let userToken: ReturnType = {
    success: true,
    token: '',
  };

  if (mustCheckToken) {
    userToken = await checkToken();
  }

  try {
    const cancelTokenSource = cancelSource || axios.CancelToken.source();

    const config: AxiosRequestConfig = {
      method,
      headers: {
        'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
        Authorization: userToken.token,
      },
      url: anotherDomain ? path : API_URL + path,
      data: data || {},
      responseType: payload.isBlobResponseType ? 'blob' : 'json',
      cancelToken: cancelTokenSource.token,  // Передаємо токен скасування
    };

    const response = await axios(config);
    return response.data;
  } catch (e: any) {
    if (axios.isCancel(e)) {
      console.log('Запит скасовано:', e.message);
    } else {
      console.log('Помилка запиту:', e);
      throw e;
    }
  }
}
